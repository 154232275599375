<template>
  <full-page-gradient color="blue">
    <v-container>
      <v-layout justify-center class="no-grow">
        <v-flex xs11>
          <typography type="h3">Page not found</typography>
        </v-flex>
      </v-layout>
      <v-layout justify-center column>
        <v-flex xs12>
          <typography :type="$vuetify.breakpoint.xsOnly ? 'body' : 'h5'" light
            >Sorry, we couldn't find the page you were looking for.</typography
          >
        </v-flex>
        <v-flex xs12 mt-4>
          <round-button @click="onHomeClick"
            >Start a new application</round-button
          >
        </v-flex>
      </v-layout>
    </v-container>
  </full-page-gradient>
</template>

<script>
import FullPageGradient from '@/components/templates/FullPageGradient.vue';
import Typography from '@/components/atoms/Typography.vue';
import RoundButton from '@/components/atoms/RoundButton.vue';
import { LANDING } from '@/constants/routes';

export default {
  components: {
    Typography,
    FullPageGradient,
    RoundButton
  },
  methods: {
    onHomeClick() {
      window.location.href = LANDING;
    }
  }
};
</script>
